



























































































import { Component, Vue } from 'vue-property-decorator';
import Header from '@/components/header.vue'; // @ is an alias to /src
import Loader from '@/components/loader.vue'; // @ is an alias to /src
import ProductMasterSidebar from '@/components/product-master-sidebar.vue';
import productService from '@/services/product-service';

@Component({
  components: {
    Header,
    Loader,
    ProductMasterSidebar
  },
})
export default class CategoryLevel2 extends Vue {
public fields = [{key: 'title', sortable: true }, {key: 'actions', label: 'Actions'}];
    loading = true;
    filter = null;
    filterOn = [];
    error_mesg = null;
  public items= [];
  private object: any = { title: ""};

public currentPage = 1;
get rows(){return this.items.length;}
  
  retrieve() {
    this.loading = true;
    productService.get_sub_level_1_category('').then((response) => {
        this.items = response.data;
        this.loading = false;
    })
      .catch((e) => {
        this.loading = false;
      });
  }

  handleSubmit(){

    this.create();
  }
  create() {
    this.loading = true;
     this.error_mesg = null;
    if(this.object['id'] != null){
        productService.update_sub_level_1_category(this.object['id'], this.object)
      .then((response) => {
        if(response){
          this.retrieve();
          this.object = {title : ""}
              this.loading = false;
        }
      })
      .catch((e) => {
          this.loading = false;
      });
    }else{
       productService.create_sub_level_1_category(this.object)
      .then((response) => {
        if(response){
          this.retrieve();
          this.object = {title : ""}
              this.loading = false;
        }
      })
      .catch((e) => {
          this.loading = false;
      });
    }
  }
     onFiltered(filteredItems:any) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        // this.rows = filteredItems.length
        // this.currentPage = 1
      }
  
  delete(id: any){
     productService.delete_sub_level_1_category(id)
      .then((response) => {
        if(response){
          this.retrieve();
          this.object = {id: null, title : ""}
            this.loading = false;
        }
      })
      .catch((e) => {
        let eror = e.response.data;
        let r = eror[Object.keys(eror)[0]];
        this.error_mesg = r;
          this.loading = false;
      });
  }

  info(row: any){
    this.object['id'] = row['id']; 
    this.object['title'] = row['title'];    
  }
  removeinfo(id: any){
    this.delete(id);
  }
  mounted() {
  this.retrieve();
  }
}
